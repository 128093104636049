<template>
    <div class="is-flex is-flex-direction-column" style="min-height:100vh;">
        <Navbar />
        <router-view class="is-flex-grow-1 mt-1 mb-1" />
        <AnnotatorFooter v-if="route.name=='Annotator'" />
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';

import Navbar from "@/components/layout/navbar";
import AnnotatorFooter from '@/components/annotator/AnnotatorFooter';

const route = useRoute();
</script>