<template>
    <footer class="footer has-background-black py-0" style="width: 100vmax; height: 50px;">
        <div class="buttons level mt-2">
            <div class="level-left">
                <button v-if="footerButtons.undo" v-on:click="undo" class="button level-item is-primary is-small is-rounded" style="background-color: #fd4d2d;">
                    <p class="is-size-6 has-text-weight-semibold">Undo</p>
                </button>
                <button v-if="footerButtons.skip" v-on:click="skip" class="button level-item is-primary is-small is-rounded" style="background-color: #fd4d2d;">
                    <p class="is-size-6 has-text-weight-semibold">Skip</p>
                </button>
            </div>
            <h1 v-if="!noTask" class="level-item has-text-white is-flex-grow-1" style="text-align: center;">
                Task ID: {{ task.id }} &mdash; {{ tasksCompleted }} tasks completed
            </h1>
            <div class="level-right">
                <button v-if="footerButtons.next" v-on:click="next" class="button level-item is-primary is-small is-rounded" style="background-color: #fd4d2d;">
                    <p class="is-size-6 has-text-weight-semibold">Next</p>
                    <i class="material-icons">arrow_forward</i>
                </button>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'pinia';
import { useAnnotatorStore } from '@/stores/annotator';
export default {
    name: "AnnotatorFooter",
    computed: {
        ...mapState(useAnnotatorStore, ["noTask", "task", "tasksCompleted", "footerButtons", "callbacks"])
    },
    methods: {
        undo() {
            this.callbacks.undo();
        },
        skip() {
            this.callbacks.skip();
        },
        next() {
            this.callbacks.next();
        }
    }
};
</script>
