import gql from 'graphql-tag';

export const LOGIN = gql`
mutation auth($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
        jwtToken
    }
}
`;

export const CURRENT_ANNOTATOR = gql`
query {
    currentAnnotator {
        id
        firstName
        task {
            id
            name
            category
        }
    }
}
`;

export const ANNOTATOR_REPORT = gql`
query annotatorReport($days: Int!) {
    annotatorReport(days: $days) {
        nodes {
            taskId
            completed
            skipped
        }
    }
}
`;

export const ALL_ANNOTATOR_REPORTS = gql`
query allAnnotatorReports($days: Int!) {
    allAnnotatorReports(days: $days) {
        nodes {
            annotatorId
            reports {
                taskId
                completed
                skipped
            }
        }
    }
}
`;

export const QUEUES_REPORT = gql`
query queuesReport($days: Int!) {
    queuesReport(
        days: $days,
        filter: { or: [
            { createdCount: { greaterThan: 0 } },
            { skippedCount: { greaterThan: 0 } },
            { completedCount: { greaterThan: 0 } }
        ] }) {
        nodes {
            taskId
            createdCount
            skippedCount
            completedCount
        }
    }
}`;