export const LICENSE_PLATES = [
    {
        value: 'unknown',
        label: 'Unknown'
    },
    {
        value: 'incorrect',
        label: 'Not a plate'
    },
    {
        value: 'other',
        label: 'Other'
    },
    {
        value: 'decorative',
        label: 'Dealer/Decorative'
    },
    {
        value: "united_states",
        label: "United States",
        subregions: [
            { value: 'other', label: 'Other' },
            { value: 'al', label: 'Alabama' },
            { value: 'ak', label: 'Alaska' },
            { value: 'az', label: 'Arizona' },
            { value: 'ar', label: 'Arkansas' },
            { value: 'as', label: 'American Samoa' },
            { value: 'ca', label: 'California' },
            { value: 'co', label: 'Colorado' },
            { value: 'ct', label: 'Connecticut' },
            { value: 'de', label: 'Delaware' },
            { value: 'dc', label: 'District of Columbia' },
            { value: 'fl', label: 'Florida' },
            { value: 'ga', label: 'Georgia' },
            { value: 'gu', label: 'Guam' },
            { value: 'hi', label: 'Hawaii' },
            { value: 'id', label: 'Idaho' },
            { value: 'il', label: 'Illinois' },
            { value: 'in', label: 'Indiana' },
            { value: 'ia', label: 'Iowa' },
            { value: 'ks', label: 'Kansas' },
            { value: 'ky', label: 'Kentuckey' },
            { value: 'la', label: 'Louisiana' },
            { value: 'me', label: 'Maine' },
            { value: 'md', label: 'Maryland' },
            { value: 'ma', label: 'Massachusetts' },
            { value: 'mi', label: 'Michigan' },
            { value: 'mn', label: 'Minnesota' },
            { value: 'ms', label: 'Mississippi' },
            { value: 'mo', label: 'Missouri' },
            { value: 'mt', label: 'Montana' },
            { value: 'ne', label: 'Nebraska' },
            { value: 'nv', label: 'Nevada' },
            { value: 'nh', label: 'New Hampshire' },
            { value: 'nj', label: 'New Jersey' },
            { value: 'nm', label: 'New Mexico' },
            { value: 'ny', label: 'New York' },
            { value: 'nc', label: 'North Carolina' },
            { value: 'nd', label: 'North Dakota' },
            { value: 'cm', label: 'Northern Mariana Islands' },
            { value: 'oh', label: 'Ohio' },
            { value: 'ok', label: 'Oklahoma' },
            { value: 'or', label: 'Oregon' },
            { value: 'pa', label: 'Pennsylvania' },
            { value: 'pr', label: 'Puerto Rico' },
            { value: 'ri', label: 'Rhode Island' },
            { value: 'sc', label: 'South Carolina' },
            { value: 'sd', label: 'South Dakota' },
            { value: 'tn', label: 'Tennessee' },
            { value: 'tx', label: 'Texas' },
            { value: 'ut', label: 'Utah' },
            { value: 'vt', label: 'Vermont' },
            { value: 'va', label: 'Virginia' },
            { value: 'vi', label: 'Virgin Islands' },
            { value: 'wa', label: 'Washington' },
            { value: 'wv', label: 'West Virginia' },
            { value: 'wi', label: 'Wisconsin' },
            { value: 'wy', label: 'Wyoming' }
        ]
    },
    {
        value: "canada",
        label: "Canada",
        subregions: [
            { value: 'other', label: 'Other' },
            { value: "ab", label: "Alberta" },
            { value: "bc", label: "British Columbia" },
            { value: "mb", label: "Manitoba" },
            { value: "nb", label: "New Brunswick" },
            { value: "nl", label: "Newfoundland and Labrador" },
            { value: "ns", label: "Nova Scotia" },
            { value: "on", label: "Ontario" },
            { value: "pe", label: "Prince Edward Island" },
            { value: "qc", label: "Quebec" },
            { value: "sk", label: "Saskatchewan" }
        ]
    },
    {
        value: "mexico",
        label: "Mexico",
        subregions: [
            { value: 'other', label: 'Other' },
            { value: "ag", label: "Aguascalientes" },
            { value: "bn", label: "Baja California" },
            { value: "bs", label: "Baja California Sur" },
            { value: "cp", label: "Campeche" },
            { value: "ch", label: "Coahuila" },
            { value: "cl", label: "Colima" },
            { value: "cs", label: "Chiapas" },
            { value: "ci", label: "Chihuahua" },
            { value: "df", label: "Mexico City" },
            { value: "dg", label: "Durango" },
            { value: "gj", label: "Guanajuato" },
            { value: "ge", label: "Guerrero" },
            { value: "hd", label: "Hidalgo" },
            { value: "ja", label: "Jalisco" },
            { value: "mx", label: "Mexico" },
            { value: "mc", label: "Michoacán" },
            { value: "mr", label: "Morelos" },
            { value: "na", label: "Nayarit" },
            { value: "nl", label: "Nuevo León" },
            { value: "oa", label: "Oaxaca" },
            { value: "pu", label: "Puebla" },
            { value: "qe", label: "Querétaro" },
            { value: "qi", label: "Quintana Roo" },
            { value: "sl", label: "San Luis Potosí" },
            { value: "si", label: "Sinaloa" },
            { value: "so", label: "Sonora" },
            { value: "tb", label: "Tabasco" },
            { value: "ta", label: "Tamaulipas" },
            { value: "tl", label: "Tlaxcala" },
            { value: "vc", label: "Veracruz" },
            { value: "yu", label: "Yucatán" },
            { value: "za", label: "Zacatecas" }
        ]
    },
    {
        value: 'albania',
        label: 'Albania'
    },
    {
        value: 'andorra',
        label: 'Andorra'
    },
    {
        value: 'armenia',
        label: 'Armenia'
    },
    {
        value: 'austria',
        label: 'Austria'
    },
    {
        value: 'azerbaijan',
        label: 'Azerbaijan'
    },
    {
        value: 'belarus',
        label: 'Belarus'
    },
    {
        value: 'belgium',
        label: 'Belgium'
    },
    {
        value: 'bosnia',
        label: 'Bosnia'
    },
    {
        value: 'brazil',
        label: 'Brazil'
    },
    {
        value: 'bulgaria',
        label: 'Bulgaria'
    },
    {
        value: 'china',
        label: 'China'
    },
    {
        value: 'croatia',
        label: 'Croatia'
    },
    {
        value: 'cyprus',
        label: 'Cyprus'
    },
    {
        value: 'czech_republic',
        label: 'Czech Republic'
    },
    {
        value: 'denmark',
        label: 'Denmark'
    },
    {
        value: 'djibouti',
        label: 'Djibouti'
    },
    {
        value: 'estonia',
        label: 'Estonia'
    },
    {
        value: 'finland',
        label: 'Finland'
    },
    {
        value: 'france',
        label: 'France'
    },
    {
        value: 'georgia',
        label: 'Georgia'
    },
    {
        value: 'gibraltar',
        label: 'Gibraltar'
    },
    {
        value: 'germany',
        label: 'Germany'
    },
    {
        value: 'greece',
        label: 'Greece'
    },
    {
        value: 'hong_kong',
        label: 'Hong Kong'
    },
    {
        value: 'hungary',
        label: 'Hungary'
    },
    {
        value: 'ireland',
        label: 'Ireland'
    },
    {
        value: 'israel',
        label: 'Israel'
    },
    {
        value: 'italy',
        label: 'Italy'
    },
    {
        value: 'kazakhstan',
        label: 'Kazakhstan'
    },
    {
        value: 'kyrgyzstan',
        label: 'Kyrgyzstan'
    },
    {
        value: 'latvia',
        label: 'Latvia'
    },
    {
        value: 'liechtenstein',
        label: 'Liechtenstein'
    },
    {
        value: 'lithuania',
        label: 'Lithuania'
    },
    {
        value: 'luxembourg',
        label: 'Luxembourg'
    },
    {
        value: 'moldova',
        label: 'Moldova'
    },
    {
        value: 'monaco',
        label: 'Monaco'
    },
    {
        value: 'montenegro',
        label: 'Montenegro'
    },
    {
        value: 'netherlands',
        label: 'Netherlands'
    },
    {
        value: 'new_zealand',
        label: 'New Zealand'
    },
    {
        value: 'north_macedonia',
        label: 'North Macedonia'
    },
    {
        value: 'norway',
        label: 'Norway'
    },
    {
        value: 'palestinian_authority',
        label: 'Palestinian Authority'
    },
    {
        value: 'poland',
        label: 'Poland'
    },
    {
        value: 'romania',
        label: 'Romania'
    },
    {
        value: 'russia',
        label: 'Russia'
    },
    {
        value: 'serbia',
        label: 'Serbia'
    },
    {
        value: 'singapore',
        label: 'Singapore'
    },
    {
        value: 'slovakia',
        label: 'Slovakia'
    },
    {
        value: 'slovenia',
        label: 'Slovenia'
    },
    {
        value: 'spain',
        label: 'Spain'
    },
    {
        value: 'sweden',
        label: 'Sweden'
    },
    {
        value: 'switzerland',
        label: 'Switzerland'
    },
    {
        value: 'tajikistan',
        label: 'Tajikistan'
    },
    {
        value: 'turkey',
        label: 'Turkey'
    },
    {
        value: 'uganda',
        label: 'Uganda'
    },
    {
        value: 'ukraine',
        label: 'Ukraine'
    },
    {
        value: 'united_arab_emirates',
        label: 'United Arab Emirates'
    },
    {
        value: 'united_kingdom',
        label: 'United Kingdom'
    },
    {
        value: 'uzbekistan',
        label: 'Uzbekistan'
    }
];