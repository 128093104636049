import { createApp } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';

import App from '@/App.vue';
import pinia from '@/stores';
import router from '@/router';
import { apolloProvider, apolloClient } from '@/apollo';


import vSelect from 'vue-select';

require('@/assets/styles/main.css');

const app = createApp(App);
app.provide(DefaultApolloClient, apolloClient);
app.component('v-select', vSelect);
app.use(pinia);
app.use(router);
app.use(apolloProvider);
app.mount('#app');
