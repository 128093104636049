import { defineStore } from 'pinia';

const state = () => {
    let routes = {};
    const routeNames = [
        'BrowserFacesList', 'BrowserPersonsList', 'BrowserPersonsDetail', 'BrowserObjectsList',
        'BrowserTattoosList', 'BrowserIrisesList', 'BrowserFingerprintsList', 'BrowserImagesList'
    ];
    routeNames.forEach(routeName => {
        routes[routeName] = {
            pageInfo: {
                startCursor: null,
                endCursor: null,
                hasNextPage: false,
                hasPreviousPage: false
            },
            currentCursor: null,
            after: true,
            filter: {},
            orderBy: "ID_ASC"
        };
    });

    return {
        perPage: 48,
        routes: routes,
        personDetailID: -1
    };
};

const actions = {
    next(route) {
        this.routes[route].after = true;
        this.routes[route].currentCursor = this.routes[route].pageInfo.endCursor;
    },
    previous(route) {
        this.routes[route].after = false;
        this.routes[route].currentCursor = this.routes[route].pageInfo.startCursor;
    },
    setFilter(route, filter) { // search()
        this.resetRoute(route, false);
        this.routes[route].filter = filter;
    },
    setOrderBy(route, orderBy) {
        this.routes[route].orderBy = orderBy
    },
    setPageInfo(route, pageInfo) {
        this.routes[route].pageInfo = pageInfo;
    },
    setPersonDetailID(id) {
        this.personDetailID = id;
    },
    // If resetFilter is false (upon search), don't reset it. If true, reset it. 
    resetRoute(route, resetFilter) {
        if(resetFilter) {this.routes[route].filter = {};}
        this.routes[route].orderBy = "ID_ASC";
        this.routes[route].pageInfo = {
            startCursor: null,
            endCursor: null,
            hasNextPage: false,
            hasPreviousPage: false,
        };
        this.routes[route].currentCursor = null;
        this.routes[route].after = true;
    }
};

export const useBrowserStore = defineStore('browser', {
    state: state,
    actions: actions,
    persist: {
        storage: window.sessionStorage
    }
});