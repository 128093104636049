import jwt_decode from "jwt-decode";

import { createApolloProvider } from '@vue/apollo-option';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from "@apollo/client/link/retry";

import router from "@/router";
import { useAuthStore } from '@/stores/auth';

const auth = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const authStore = useAuthStore();
    const token = authStore.token;

    // Return the headers to the context so httpLink can read them
    var ret = {
        headers: {
            ...headers,
        }
    };

    // Check if token is expired, if not add to headers
    if (token) {
        const { exp } = jwt_decode(token);
        const expirationTime = (exp * 1000) - 60000;
        if (Date.now() >= expirationTime) {
            authStore.logout()
            .then(() => {
                router.push({ path: "/login", query: { redirect: window.location.pathname }});
                return;
            });
        }
        ret.headers.authorization = `Bearer ${token}`;
    }
    return ret;
});

const retry = new RetryLink();
const link = new HttpLink({
    uri: process.env.NODE_ENV === "production" ? 'https://metadata.roc.ai/graphql'
                                               : 'http://metadata.roc.ai:5000/graphql',
    fetch
});

export const apolloClient = new ApolloClient({
    link: auth.concat(retry).concat(link),
    cache: new InMemoryCache({
        addTypename: true
    })
});

export const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});