import gql from 'graphql-tag';

export const RESERVE_TASKS = gql`
mutation reserve($taskId: Int!, $numTasks: Int!) {
    reserveTasks(input: { taskId: $taskId, numTasks: $numTasks}) {
        taskItems {
            id
            data
        }
    }
}
`;

export const RESOLVE_TASK = gql`
mutation resolve($taskItemId: Int!, $newStatus: String!) {
    resolveTask(input: { taskItemId: $taskItemId, newStatus: $newStatus }) {
        taskItem {
            id
            status
        }
    }
}
`;