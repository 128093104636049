import { createRouter, createWebHistory } from 'vue-router';

import { useAuthStore } from '@/stores/auth';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import("@/views/login"),
        meta: {
            noAuth: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home')
    },
    {
        path: '/annotator',
        name: 'Annotator',
        component: () => import('@/views/annotator')
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/views/admin'),
        meta: {
            requiresAdmin: true
        },
        children: [
            {
                path: '',
                name: 'AdminHome',
                component: () => import('@/views/admin/AdminHome')
            },
            {
                path: 'annotators',
                name: 'AdminAnnotatorList',
                component: () => import('@/views/admin/AdminAnnotatorList')
            },
            {
                path: 'annotators/:id',
                name: 'AdminAnnotatorDetail',
                component: () => import('@/views/admin/AdminAnnotatorDetail'),
            }
        ]
    },
    {
        path: '/browser',
        name: 'Browser',
        component: () => import('@/views/browser'),
        meta: {
            requiresAdmin: true
        },
        children: [
            {
                path: '',
                name: 'BrowserHome',
                component: () => import('@/views/browser/BrowserHome')
            },
            {
                path: 'persons',
                name: 'BrowserPersons',
                component: () => import('@/views/browser/BrowserPersons'),
                children: [
                    {
                        path: '',
                        name: 'BrowserPersonsList',
                        component: () => import('@/views/browser/BrowserList'),
                        props: { listType: 'person' }
                    },
                    {
                        path: ':id',
                        name: 'BrowserPersonsDetail',
                        component: () => import('@/views/browser/BrowserPersonsDetail')
                    }
                ]
            },
            {
                path: 'faces',
                name: 'BrowserFaces',
                component: () => import('@/views/browser/BrowserFaces'),
                children: [
                    {
                        path: '',
                        name: 'BrowserFacesList',
                        component: () => import('@/views/browser/BrowserList'),
                        props: { listType: 'face' }
                    },
                    {
                        path: ':id',
                        name: 'BrowserFacesDetail',
                        component: () => import('@/views/browser/BrowserFacesDetail')
                    }
                ]
            },
            {
                path: 'objects',
                name: 'BrowserObjects',
                component: () => import('@/views/browser/BrowserObjects'),
                children: [
                    {
                        path: '',
                        name: 'BrowserObjectsList',
                        component: () => import('@/views/browser/BrowserList'),
                        props: { listType: 'object' }
                    },
                    {
                        path: ':id',
                        name: 'BrowserObjectsDetail',
                        component: () => import('@/views/browser/BrowserObjectsDetail')
                    },
                ]
            },
            {
                path: 'fingerprints',
                name: 'BrowserFingerprints',
                component: () => import('@/views/browser/BrowserFingerprints'),
                children: [
                    {
                        path: '',
                        name: 'BrowserFingerprintsList',
                        component: () => import('@/views/browser/BrowserList'),
                        props: { listType: 'fingerprint' }
                    },
                    {
                        path: ':id',
                        name: 'BrowserFingerprintsDetail',
                        component: () => import('@/views/browser/BrowserFingerprintsDetail')
                    },
                ]
            },
            {
                path: 'irises',
                name: 'BrowserIrises',
                component: () => import('@/views/browser/BrowserIrises'),
                children: [
                    {
                        path: '',
                        name: 'BrowserIrisesList',
                        component: () => import('@/views/browser/BrowserList'),
                        props: { listType: 'iris' }
                    },
                    {
                        path: ':id',
                        name: 'BrowserIrisesDetail',
                        component: () => import('@/views/browser/BrowserIrisesDetail')
                    },
                ]
            },
            {
                path: 'tattoos',
                name: 'BrowserTattoos',
                component: () => import('@/views/browser/BrowserTattoos'),
                children: [
                    {
                        path: '',
                        name: 'BrowserTattoosList',
                        component:  () => import('@/views/browser/BrowserList'),
                        props: { listType: 'tattoo' }
                    },
                    {
                        path: ':id',
                        name: 'BrowserTattoosDetail',
                        component: () => import('@/views/browser/BrowserTattoosDetail')
                     }
                ]
            },
            {
                path: 'images',
                name: 'BrowserImages',
                component: () => import('@/views/browser/BrowserImages'),
                children: [
                    {
                        path: '',
                        name: 'BrowserImagesList',
                        component: () => import('@/views/browser/BrowserList')
                    },
                    {
                        path: ':id',
                        name: 'BrowserImagesDetail',
                        component: () => import('@/views/browser/BrowserImagesDetail')
                    }
                ]
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/err/404')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.noAuth) {
        next();
    } else {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({
                path: "/login",
                query: { redirect: to.fullPath }
            });
        } else {
            if (to.meta.requiresAdmin && !authStore.isAdmin) {
                next({ path: '/404' });
            } else {
                next();
            }
        }
    }
});

export default router;