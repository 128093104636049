import { defineStore } from 'pinia';
import jwt_decode from 'jwt-decode';

import { LOGIN, CURRENT_ANNOTATOR } from '@/graphql/auth';
import { apolloClient } from '@/apollo';
import { useAnnotatorStore } from '@/stores/annotator';

const state = () => ({
    token:     null,
    annotator: {}
});

const getters = {
    isAuthenticated: (state) => state.token !== null,
    isAdmin: (state) => {
        const role = state.token ? jwt_decode(state.token).role : 'metadata_anonymous';
        return ['metadata_employee', 'metadata_superuser'].includes(role);
    }
};

const actions = {
    async login({email, password}) {
        const loginResult = await apolloClient.mutate({
            mutation: LOGIN,
            variables: {
                email,
                password
            }
        });
        const token = loginResult.data.authenticate.jwtToken;
        if (!token)
            throw(Error('Could not login!'));

        this.token = token;

        const annotatorResult = await apolloClient.query({
            query: CURRENT_ANNOTATOR,
        });
        this.annotator = annotatorResult.data.currentAnnotator;
    },
    async logout() {
        const annotatorStore = useAnnotatorStore();
        annotatorStore.$reset();
        this.$reset();
    }
};

export const useAuthStore = defineStore('auth', {
    state: state,
    getters: getters,
    actions: actions,
    persist: {
        storage: window.sessionStorage
    }
});